<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md9 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
				</span>
				{{ getTitle() }}
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table class="detail-table table-head-sticky" width="100%">
				<thead>
					<tr>
						<th width="5%"  class="p-2 light-blue-bg">Image</th>
						<th width="7%"  class="p-2 light-blue-bg text-center">Barcode</th>
						<th width="10%" class="p-2 light-blue-bg">Asset Info</th>
						<th width="8%" class="p-2 light-blue-bg">Services Status</th>
						<th width="7%" class="p-2 light-blue-bg">Services Type</th>
						<th width="10%" class="p-2 light-blue-bg">Serviced By</th>
						<th width="15%" class="p-2 light-blue-bg">Expected Date</th>
						<th width="15%" class="p-2 light-blue-bg">Actual Date</th>
						<th width="3%"  class="p-2 light-blue-bg">Quantity</th>
						<th width="8%" class="p-2 light-blue-bg">Purchased Date</th>
					</tr>
				</thead>
				<tbody v-if="retirements.length">
					<tr v-for="(row, index) in retirements" :key="index">		
						<td class="px-2 py-2 my-auto  border-top-light-grey">
						  <ImageTemplate circle style="max-width: 50px" :src="row?.image" />
						</td>						
						<td class="px-2 py-2 my-auto font-level-1 text-black border-top-light-grey">
								<div
									v-if="row?.pico_barcode"
									v-on:click="openPopup(row)"
									class="text-center"
								>
									<label class="font-size-14 mb-1 ">
										<v-icon size="30">mdi-barcode-scan</v-icon>
									</label>
									<div class="font-size-14 fw-600 mb-1 w-100">
										{{ row?.pico_barcode }}
									</div>
								</div>
								<div v-else>
									<em class="text-muted">No Barcode</em>
								</div>
						</td>
						<td  class="p-2 border-top-light-grey">
							<div class="d-flex align-items-center">
								<div class="ml-2">
									<div>
										<label class="font-size-14 mb-1">
											<b>
											    <v-icon size="18">mdi-pound</v-icon>
											</b>
										</label>
										{{ row.barcode }}
									</div>

									<div style="width: 250px; white-space: break-spaces">
										<label class="mb-1 bold-600">Name : </label>
										    <span class="bold-500">{{ row.asset_name }}</span>
									</div>
									<div  style="width: 250px; white-space: break-spaces">
										<label class="mb-1 bold-600">Category : </label>
										{{ row.group }}
									</div>
								</div>
							</div>
						</td>													
						<td  class="p-2 border-top-light-grey">
							<v-chip v-if="row.status === 'Started'" color="blue darken-4" text-color="white">Started</v-chip>
							<v-chip v-else-if="row.status === 'Completed'" color="green" text-color="white">Completed</v-chip>
							<v-chip v-else color="amber darken-4" text-color="white">Scheduled</v-chip>
						</td>							
						<td  class="p-2 border-top-light-grey">
							{{ row.service_type }}
						</td>	
						<td class="p-2 border-top-light-grey">
							{{ row.service_by_formatted }}
							<span v-if="row.member_display_name"> ({{ row.member_display_name }})</span>
						</td>
						<td  class="p-2 border-top-light-grey cursor-pointer" width="230">
							<div class="d-flex align-items-center">
								<div class="ml-2">
									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="cyan" content="S" class="badge-new"></v-badge>
												</label>
											</template>
											<span> Start Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.expected_start_date }}
										</v-chip>
									</div>
									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="red" content="E" class="badge-new"></v-badge>
												</label>
											</template>
											<span> End Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.expected_end_date }}
										</v-chip>
									</div>
								</div>
							</div>
						</td>
						<td  class="p-2 border-top-light-grey cursor-pointer" width="230">
							<div class="d-flex align-items-center">
								<div class="ml-2">
									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="cyan" content="S" class="badge-new"></v-badge>
												</label>
											</template>
											<span> Start Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.actual_start_date }}
										</v-chip>
									</div>
									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="red" content="E" class="badge-new"></v-badge>
												</label>
											</template>
											<span> End Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.actual_end_date }}
										</v-chip>
									</div>
								</div>
							</div>
						</td>							
						<td  class="p-2 border-top-light-grey">
							{{ row.quantity }}
						</td>
						<td class="p-2 border-top-light-grey">
							{{ row.created_at }}
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="10">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no {{ getTitle() }} at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<v-dialog
			origin="center center"
			v-model="barcodeDialog"
			persistent
			scrollable
			content-class="common-dialog"
			:max-width="500"
		>
			<v-card>
				<v-card-title
					class="headline dialog-custom-header text-uppercase custom-border-bottom custom-header-blue-text white-background"
				>
					{{ barcodeDialogTitle }}
				</v-card-title>
				<v-card-text>
					<div class="text-center mt-2">
						<img v-if="popup_image" class="mb-4" :src="popup_image" :alt="popup_text" width="400px" />
						<h3>{{ popup_text }}</h3>
					</div>
				</v-card-text>
				<v-divider class="my-0"></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn depressed tile v-on:click="barcodeDialog = false">Close </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	

	</div>
	
</template>

<script>
import ApiService from "@/core/services/api.service";
import { EventBus } from "@/core/event-bus/event.bus";
import ImageTemplate from "@/view/components/Image";


export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		uuid: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: "all",
		},
	},
	data() {
		return {
			retirements: [],
			current_page: 1,
			total_page: 1,
			pageLoading: true,
			barcodeDialog: false,
			barcodeDialogTitle: null,
			popup_image: null,
			popup_text: null,
		};
	},
	methods: {
		openPopup(data) {
			this.popup_image = data.barcode_image;
			this.popup_text = data.pico_barcode;

			this.barcodeDialogTitle = `${data.asset_name ? data.asset_name : ""} (#${data.barcode})`;
			this.barcodeDialog = true;
			return false;
		},
		getTitle() {
			return "Retire Stocks";
		},
		getretirements(page = 1) {
			let status = this.status;
			this.pageLoading = true;
			ApiService.query(`${this.type}/${this.uuid}/histories`, { status, page })
				.then(({ data }) => {
					this.retirements = data.tbody;
					this.current_page = data.current_page;
					this.total_page = data.total_page;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		fetchPage(page) {
			this.getretirements(page);
		},
	},
	components: {		
		ImageTemplate,
	},
	mounted() {
		this.getretirements();

		EventBus.$on("reload:asset-reservation", () => {
			this.getretirements();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:asset-reservation");
	},
};
</script>
